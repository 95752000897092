import React from 'react'
import LoadableVisibility from 'react-loadable-visibility/react-loadable'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Loading from '../components/loading'

const OurBrands = LoadableVisibility({
  loader: () => import('../sections/OurBrands'),
  loading: () => <Loading />,
  delay: 300,
})

const RegisterInfo = LoadableVisibility({
  loader: () => import('../sections/RegisterInfo'),
  loading: () => <Loading />,
  delay: 300,
})

const HowToRegister = () => (
  <Layout>
    <SEO />
    <RegisterInfo />
    <OurBrands />
  </Layout>
)

export default HowToRegister
