import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import objectFitImages from 'object-fit-images'
import LoadableVisibility from 'react-loadable-visibility/react-loadable'
import Loading from '../components/loading'

import theme from '../util/theme'

const Footer = LoadableVisibility({
  loader: () => import('../sections/footer'),
  loading: () => <Loading />,
  delay: 300,
})

class Layout extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      const addthisScript = document.createElement('script')
      addthisScript.setAttribute(
        'src',
        '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-53033c65626f7d55'
      )
      if (document.body) document.body.appendChild(addthisScript)
    }, 300)
    objectFitImages()
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <main>
          {this.props.children}
          <Footer />
        </main>
      </ThemeProvider>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
