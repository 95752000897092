import React from 'react'
import styled from 'styled-components'
import Loader from 'react-loader-spinner'

const StyledLoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
`

const Loading = () => {
  return (
    <StyledLoadingWrapper>
      <Loader type="ThreeDots" color="#081E3F" height="20" width="50" />
    </StyledLoadingWrapper>
  )
}

export default Loading
